<template>
  <div class="ibox availabilities">
    <div class="ibox-title">
      <h2>{{ $t('hospitality.availabilities.list.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <Select
        v-if="apartments.length > 0"
        :label="$t('hospitality.availabilities.list.select.label')"
        :placeholder="$t('hospitality.availabilities.list.select.placeholder')"
        :selectOptions="{
          options: apartments,
          getOptionLabel : option => getLocalizedText(option.title)
        }"
        v-bind:value.sync="form.apartment" 
        :labelAsColumn="true"
      />

      <div class="row" v-if="form.apartment">

        <p v-if="showNumberOfRows">Number of rows: {{numberOfRows}}</p>
      
        <b-table 
            outlined striped
            :items="availabilities"
            :fields="listFields"
            show-empty
            :empty-text="$t('hospitality.availabilities.list.no-availability')"
            ref="listItems">
          <template v-slot:cell(select)="row">
            <p-check class="p-default p-curve p-primary" color="success" :value="row.item._id" v-model="listItemsSelected"></p-check>
          </template>       
          <template v-slot:cell(title)="row">
            {{getLocalizedText(row.item.title)}}
          </template> 
          <template v-slot:cell(periodValidation)="row">
            <i :class="['fa','fa-check',row.item.periodValidation ? 'active' : 'disabled']"></i>
            <span v-if="row.item.periodValidation">
              <br/>
              {{ $t('hospitality.availabilities.info.expectedValidationDate') + ': ' + formatDay(row.item.periodValidation.expectedValidationDate) }}<br/>
              {{ $t('hospitality.availabilities.info.lastDateBeforeValidationPeriod') + ': ' + (row.item.periodValidation.lastDateBeforeValidationPeriod ? formatDay(row.item.periodValidation.lastDateBeforeValidationPeriod) : $t('hospitality.availabilities.info.noLastDateBeforeValidationPeriod')) }}
            </span>
          </template> 
          <template v-slot:cell(unavailalePeriods)="row">
            <ul v-if="row.item.unavailablePeriods && row.item.unavailablePeriods.length > 0">
              <li v-for="(unavailablePeriod, index) in row.item.unavailablePeriods" :key="index + '_unavailablePeriod_' + row.item._id">{{ formatDay(unavailablePeriod.startDate, "fr", {type:"NUMERIC"}) + ' - ' + formatDay(unavailablePeriod.endDate, "fr", {type:"NUMERIC"}) }}</li>
            </ul>
            <span v-else>{{ $t('hospitality.availabilities.unavailable-periods.list.no-period') }}</span>
          </template> 
          <template v-slot:cell(options)="row">
            <span v-if="isAvailabilityUpdateAllowed">
              <a href="javascript:void(0)" @click="showUpdateModal(row.item)">{{$t('hospitality.availabilities.list.options.update')}}</a>
              /
              <a href="javascript:void(0)" @click="showUnavailablePeriodsModal(row.item)">{{$t('hospitality.availabilities.list.options.unavailablePeriods')}}</a>
              /
            </span>
            <span v-if="isAvailabilityRemoveAllowed">
              <a href="javascript:void(0)" @click="confirmRemoveItem(row.item)">{{$t('hospitality.availabilities.list.options.delete')}}</a>
            </span>
          </template>
        </b-table>

        <button v-if="isAvailabilityCreateAllowed" class="btn btn-primary" @click="showCreateItemModal()">{{$t('hospitality.availabilities.list.create-button')}}</button>
        <button v-if="isAvailabilityPublishAllowed" class="btn btn-primary ml-2" @click="showPublishModal()">{{$t('hospitality.availabilities.list.publish-button')}}</button>
        
        <b-modal size="xl" ref="createItemModal" :no-enforce-focus="true" :title="$t('hospitality.availabilities.create.title')" hide-footer lazy>
          <AvailabilityForm v-on:availability-created="onItemCreated" />
        </b-modal>

        <b-modal size="xl" ref="updateItemModal" :no-enforce-focus="true" :title="$t('hospitality.availabilities.update.title', {availabilityID: itemToUpdate._id})" hide-footer lazy>
          <AvailabilityForm :availability="itemToUpdate" v-on:availability-updated="onItemUpdated" />
        </b-modal>

        <b-modal size="xl" ref="unavailablePeriodsModal" :no-enforce-focus="true" :title="$t('hospitality.availabilities.unavailable-periods.title', {availabilityID: itemToUpdate._id})" hide-footer lazy>
          <ListUnavailablePeriods :availability="itemToUpdate" v-on:availability-updated="onItemUpdated" />
        </b-modal>

        <b-modal size="xl" ref="publishItemModal" :title="$t('hospitality.availabilities.publish.title', {apartmentID: (selectedApartment && selectedApartment.apartment) ? selectedApartment.apartment._id : ''})" hide-footer lazy>
          <PublishAvailabilities />
        </b-modal>

        <b-modal ref="removeItemModal" 
            hide-header
            @ok="removeItem">
          {{$t('hospitality.availabilities.list.delete-confirmation', {apartmentID: itemToRemove._id, apartmentTitle: getLocalizedText(itemToRemove.title)})}}
        </b-modal>

      </div>

    </div>
  </div>
</template>

<style scoped>
  .fa-check.active, .fa-user-circle-o.active {
    color:green;
  }
  .fa-check.disabled, .fa-user-circle-o.disabled {
    color:red;
  }

  
</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, ComputedRef } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import Select from '@fwk-client/components/panels/input/Select.vue';
import { languagesTypes } from '@fwk-client/store/types';
import { formatDay } from '@igotweb-node-api-utils/formatter';

import AvailabilityForm from './AvailabilityForm.vue';
import PublishAvailabilities from './Publish.vue';
import ListUnavailablePeriods from './ListUnavailablePeriods.vue';

import { useApartmentAdmin } from '../../../composables/useApartmentAdmin';
import { useAvailabilityAdmin } from '../../../composables/useAvailabilityAdmin';

export default defineComponent({
  props: {
  },
  components: {
    Select,
    AvailabilityForm,
    PublishAvailabilities,
    ListUnavailablePeriods
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { apartments, isListLoading:isApartmentsListLoading, selectApartment, selectedApartment }  = useApartmentAdmin(props, context);
    const { availabilities, isListLoading:isAvailabilitiesListLoading, 
      isAvailabilityCreateAllowed, 
      isAvailabilityPublishAllowed,
      isAvailabilityRemoveAllowed,
      isAvailabilityUpdateAllowed,
      removeAvailability }  = useAvailabilityAdmin(props, context);

    const itemToRemove:Ref<any> = ref({});
    const itemToUpdate:Ref<any> = ref({});

    const listLoading:Ref<boolean> = computed(() => {
      return isApartmentsListLoading.value || isAvailabilitiesListLoading.value;
    })

    const form:any = reactive({
        apartment: selectedApartment.value ? selectedApartment.value.apartment : undefined
      })

    const listItemsSelected:Ref<any[]> = ref([]);

    const listItems:Ref<HTMLElement|null> = ref(null);
    const removeItemModal:Ref<HTMLElement|null> = ref(null);
    const createItemModal:Ref<HTMLElement|null> = ref(null);
    const updateItemModal:Ref<HTMLElement|null> = ref(null);
    const unavailablePeriodsModal:Ref<HTMLElement|null> = ref(null);
    const publishItemModal:Ref<HTMLElement|null> = ref(null);

    const isMultiSelectAllowed:Ref<boolean> = ref(false);
    const showNumberOfRows:Ref<boolean> = ref(false);

    const numberOfRows:Ref<number> = computed(() => {
        return apartments.value.length
    })

    const listFields:Ref<any[]> = ref([
      {
        key: "select",
        label: "",
        class: (isMultiSelectAllowed.value ? "" : "hidden"),
      },
      {
        key: "title",
        label: "",
      },
      {
        key: "startDate",
        label: "",
        formatter: (value:Date, key:any, item:any) => {
          return formatDay(value, currentLanguageCode.value);
        }
      },
      {
        key: "endDate",
        label: "",
        formatter: (value:Date, key:any, item:any) => {
          return formatDay(value, currentLanguageCode.value);
        }
      },
      {
        key: "periodValidation",
        label: ""
      },
      {
        key: "unavailalePeriods",
        label: ""
      },
      {
        key: "options"
      }
    ]);

    onMounted(() => {
      updateTableLabels();
    })

    const updateTableLabels = () => {
      listFields.value[1].label = app.$t('hospitality.availabilities.list.headers.title') as string;
      listFields.value[2].label = app.$t('hospitality.availabilities.list.headers.startDate') as string;
      listFields.value[3].label = app.$t('hospitality.availabilities.list.headers.endDate') as string;
      listFields.value[4].label = app.$t('hospitality.availabilities.list.headers.periodValidation') as string;
      listFields.value[5].label = app.$t('hospitality.availabilities.list.headers.unavailablePeriods') as string;
      listFields.value[6].label = app.$t('hospitality.availabilities.list.headers.options') as string;
    }

    const currentLanguageCode:ComputedRef<string> = computed(() => {
      return $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
    })

    const onItemCreated = () => {
      // @ts-ignore
      createItemModal.value.hide()
    }

    const onItemUpdated = (item:any) => {
      itemToUpdate.value = item;
    }
    
    const confirmRemoveItem = (item:any) => {
      itemToRemove.value = item;
      // @ts-ignore
      removeItemModal.value.show()
    }

    const showUpdateModal = (item:any) => {
      itemToUpdate.value = item;
      // @ts-ignore
      updateItemModal.value.show()
    }

    const showUnavailablePeriodsModal = (item:any) => {
      itemToUpdate.value = item;
      // @ts-ignore
      unavailablePeriodsModal.value.show()
    }

    const showCreateItemModal = () => {
      // @ts-ignore
      createItemModal.value.show()
    }

    const removeItem = () => {
      removeAvailability(itemToRemove.value._id).then((removed:boolean) => {
        // We reset the user to remove
        itemToRemove.value = {};
      })
    }

    const showPublishModal = () => {
      // @ts-ignore
      publishItemModal.value.show()
    }

    watch(
      () => form.apartment,
      (val:any, oldVal:any) => {
        selectApartment(form.apartment ? form.apartment._id : undefined);     
      },
      { deep: true }
    )

    watch(
      currentLanguageCode,
      (val:any, oldVal:any) => {
       updateTableLabels();
      },
      { deep: false }
    )

    return {
      listLoading,
      apartments,
      availabilities,
      form,

      selectedApartment,


      listItemsSelected,
      listItems,
      listFields,
      isAvailabilityUpdateAllowed,
      isAvailabilityCreateAllowed,
      isAvailabilityRemoveAllowed,
      isAvailabilityPublishAllowed,
      showNumberOfRows,
      numberOfRows,

      formatDay,
      
      showCreateItemModal,
      createItemModal,
      onItemCreated,
      
      
      removeItemModal,
      itemToRemove,
      confirmRemoveItem,
      removeItem,

      showUpdateModal,
      updateItemModal,
      itemToUpdate,
      onItemUpdated,

      unavailablePeriodsModal,
      showUnavailablePeriodsModal,

      publishItemModal,
      showPublishModal
    }
  }
})
</script>