<template>
  <section id="agency" ref="agencyContent">
    <PageTitle :title="$t('hospitality.availabilities.title')" :breadcrumbs="breadcrumbs"></PageTitle>
    
    <div class="wrapper wrapper-content animated fadeInRight">

      <Header></Header>

      <div class="row" v-if="selectedAgency">
        <div class="col">
          <ListAvailabilities></ListAvailabilities>
        </div>
      </div>

    </div>
  </section>
</template>


<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, ComputedRef } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import { enableIbox, disableIbox} from '@root/src/client/theme/inspinia-jquery'; 

import { useGenericPage } from '@fwk-client/composables/useGenericPage';

import PageTitle from '@root/src/client/components/panels/PageTitle.vue';
import Header from '../panels/Header.vue';
import ListAvailabilities from '../panels/availabilities/ListAvailabilities.vue';

import { useAgencyAdmin } from '../../composables/useAgencyAdmin';

export default defineComponent({
  props: {
  },
  components: {
    PageTitle,
    ListAvailabilities,
    Header
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const genericPage = useGenericPage(props, context);
    const { agencies, selectedAgency } = useAgencyAdmin(props, context);

    const agencyContent:Ref<HTMLElement|null> = ref(null);

    const breadcrumbs = [{
      label: app.$t('home.dashboard')
    },
    {
      label: app.$t('hospitality.title')
    }]
    
    onMounted(() => {
      enableIbox(agencyContent.value!);
    })

    onBeforeUnmount(() => {
      disableIbox(agencyContent.value!);
    })

    return {
      agencyContent,
      breadcrumbs,
      selectedAgency
    }
  }
});
</script>