<template>
  
    <form role="form" @submit="onFormSubmit" name="publish-availabilities">

      <h4>{{$t('hospitality.availabilities.publish.apartment')}}</h4>
      <span>{{getLocalizedText(apartment.title)}}</span>
      <ul>
        <li v-for="(availability, index) in availabilities" :key="index + '-availability'">
          {{ formatDateTime(availability.startDate) }} - {{ formatDateTime(availability.endDate) }} : {{ getLocalizedText(availability.title) }} ({{ $t('hospitality.availabilities.info.unavailablePeriods') + ": " + (availability.unavailablePeriods != undefined ? availability.unavailablePeriods.length : 0) }})
        </li>
      </ul>


      <div class="production-apartment">
        <h4>{{$t('hospitality.availabilities.publish.production-apartment')}}</h4>
        <ul v-if="productionAvailabilities && productionAvailabilities.length > 0">
          <li v-for="(availability, index) in productionAvailabilities" :key="index + '-prod-availability' + JSON.stringify(availability)">
            {{ formatDateTime(availability.startDate) }} - {{ formatDateTime(availability.endDate) }} : {{ getLocalizedText(availability.title) }} ({{ $t('hospitality.availabilities.info.unavailablePeriods') + ": " + (availability.unavailablePeriods != undefined ? availability.unavailablePeriods.length : 0) }})
          </li>
        </ul>
        <p v-else>{{$t('hospitality.availabilities.publish.no-production-availability')}}</p>
      </div>
      
      <button class="btn btn-primary ladda-button publish-availabilities" data-style="zoom-in" type="submit">{{$t('hospitality.availabilities.publish.button')}}</button>
    </form>

</template>

<style scoped>
  .fa-check.active, .fa-user-circle-o.active {
    color:green;
  }
  .fa-check.disabled, .fa-user-circle-o.disabled {
    color:red;
  }

  
</style>

<script lang="ts">
import { toRefs, Ref, ref, defineComponent, PropType, computed, onMounted } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as Ladda from 'ladda';
import { languagesTypes } from '@fwk-client/store/types';
import { formatDay } from '@igotweb-node-api-utils/formatter';

import { useAgencyAdmin } from '../../../composables/useAgencyAdmin';
import { useAvailabilityAdmin } from '../../../composables/useAvailabilityAdmin';
import { useApartmentAdmin } from '../../../composables/useApartmentAdmin';

export default defineComponent({
  props: {
    apartment: Object as PropType<any>
  },
  components: {
      
  },
  setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const { selectedApartment }  = useApartmentAdmin(props, context);
      const { availabilities, isListLoading:isAvailabilitiesListLoading, getPublishTargetAvailabilities, publishAvailabilities }  = useAvailabilityAdmin(props, context);

      const productionAvailabilities:Ref<any[]> = ref([]);

      var laddaSubmit:Ladda.LaddaButton|null = null;

      onMounted(() => {
        var publishButton:HTMLButtonElement|null = document.querySelector( 'form[name=publish-availabilities] button.ladda-button.publish-availabilities' );
        laddaSubmit = Ladda.create(publishButton!);
      })

      var productionAvailabilitiesLoading:boolean = false;
      const getProductionAvailabilities = () => {
        getPublishTargetAvailabilities().then((availabilities:any) => {
          if(availabilities) {  
            productionAvailabilities.value = availabilities;
          }
          else {
            // There is no production availabilities
            productionAvailabilities.value = [];
          }

          productionAvailabilitiesLoading = false;
        });
      }
      // We load the production availabilities.
      getProductionAvailabilities();

      const onFormSubmit = (evt:Event) => {
        evt.preventDefault();

        laddaSubmit!.start();

        return publishAvailabilities().then((result:any) => {
          laddaSubmit!.stop();
          if(result.published) {  
            productionAvailabilities.value = result.availabilities;
          }
        })
      }

    const currentLanguageCode = $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
    
    const formatDateTime = (date:Date) => {
      return formatDay(date, currentLanguageCode);
    }

    return {
      formatDateTime,
      productionAvailabilitiesLoading,
      apartment: selectedApartment.value.apartment,
      productionApartment: selectedApartment.value.productionApartment,
      availabilities,
      productionAvailabilities,
      onFormSubmit
    }
  }
})
</script>