<template>
  <form role="form" @submit="onFormSubmit" name="availability">
    <div class="availability-form">
      <div class="row" v-if="availabilityForm.title">
        <div class="col-lg-12">
          <h2>{{getLocalizedText(availabilityForm.title)}}</h2>
        </div>
      </div>
      <div class="row" v-if="allowCreateFrom">
        <div class="col-lg-12">
          <h3>{{$t('hospitality.availabilities.create-from.title')}}</h3>
        </div>
      </div>
      <Select 
        v-if="allowCreateFrom"
        :selectOptions="{
          options: createFromApartments,
          getOptionLabel : apartment => getLocalizedText(apartment.title),
          reduce : apartment => apartment
        }"
        v-bind:value.sync="createFromForm.apartment" 
        id="createFromApartment" 
        :label="$t('hospitality.availabilities.create-from.apartment')" 
        :placeholder="$t('hospitality.availabilities.create-from.apartment_placeholder')"
        labelAsColumn 
      >
      </Select>
      <Select 
        v-if="allowCreateFrom"
        :selectOptions="{
          options: createFormAvailabilities,
          getOptionLabel : availability => getLocalizedText(availability.title),
          reduce : availability => availability
        }"
        v-bind:value.sync="createFromForm.availability" 
        id="createFromAvailability" 
        :label="$t('hospitality.availabilities.create-from.availability')" 
        :placeholder="$t('hospitality.availabilities.create-from.availability_placeholder')"
        labelAsColumn 
      >
        <template v-slot:buttons>
            <button class="btn btn-primary create-from" type="button" @click="createFromAvailability()">{{ $t('hospitality.availabilities.create-from.button') }}</button>
        </template>
      </Select>
      <div class="row">
        <div class="col-lg-12">
          <h3>{{$t('hospitality.availabilities.info.information')}}</h3>
        </div>
      </div>
      <LocalizedText 
        v-bind:value.sync="availabilityForm.title" 
        id="updateTitle" 
        :label="$t('hospitality.availabilities.info.title')" 
        :placeholder="$t('hospitality.availabilities.info.title_placeholder')" 
        :labelAsColumn="true"
        :required="false"
        :languageCodes="availableLanguageCodes"
        :rowClass="{'form-group':true, 'row':true, 'update-title':true}"
      />
      <div class="hr-line-dashed"></div>
      <SwitchCheck 
        ref="updateIsValidationRequired"
        v-bind:value.sync="availabilityForm.isValidationRequired" 
        id="updateIsValidationRequired" 
        :label="$t('hospitality.availabilities.info.isValidationRequired')" 
        :labelAsColumn="true"
      />
      <SwitchCheck 
        v-if="availabilityForm.isValidationRequired"
        ref="updateIsValidationRequiredAfterValidationDate"
        v-bind:value.sync="availabilityForm.isValidationRequiredAfterValidationDate" 
        id="updateIsValidationRequiredAfterValidationDate" 
        :label="$t('hospitality.availabilities.info.isValidationRequiredAfterValidationDate')" 
        :labelAsColumn="true"
      />
      <DateInput 
        v-if="availabilityForm.isValidationRequired"
        v-bind:value.sync="availabilityForm.expectedValidationDate" 
        :label="$t('hospitality.availabilities.info.expectedValidationDate')" 
        required 
        :disabled="!availabilityForm.isValidationRequired"
        labelAsColumn 
      />
      <DateInput 
        v-if="availabilityForm.isValidationRequired"
        v-bind:value.sync="availabilityForm.lastDateBeforeValidationPeriod" 
        :label="$t('hospitality.availabilities.info.lastDateBeforeValidationPeriod')" 
        :disabled="!availabilityForm.isValidationRequired"
        labelAsColumn 
      />
      <div class="hr-line-dashed"></div>
      <DateInput 
        v-bind:value.sync="availabilityForm.startDate" 
        :label="$t('hospitality.availabilities.info.startDate')" 
        required 
        labelAsColumn 
      />
      <DateInput 
        v-bind:value.sync="availabilityForm.endDate" 
        :label="$t('hospitality.availabilities.info.endDate')" 
        required 
        labelAsColumn 
      />
      <div class="hr-line-dashed"></div>
      <div class="row">
        <div class="col-lg-12">
          <h3>{{$t('hospitality.availabilities.info.ratings.title')}}</h3>
        </div>
      </div>
      <div class="form-group row">
        <label for="updatePriceAmount" class="col-lg-3 col-form-label">{{$t('hospitality.availabilities.info.ratings.price.amount')}}</label>
        <div class="col-lg-9">
          <input id="updatePriceAmount" type="text" :placeholder="$t('hospitality.availabilities.info.ratings.price.amount_placeholder')" class="form-control" v-model="availabilityForm.price.amount" required />
        </div>
      </div>
      <div class="form-group row">
        <label for="updatePriceCurrencyCode" class="col-lg-3 col-form-label">{{$t('hospitality.availabilities.info.ratings.price.currency')}}</label>
        <div class="col-lg-9">
          <input id="updatePriceCurrencyCode" type="text" :placeholder="$t('hospitality.availabilities.info.ratings.price.currency_placeholder')" class="form-control" v-model="availabilityForm.price.currencyCode" />
        </div>
      </div>
    </div>
    <button class="btn btn-primary ladda-button availability" data-style="zoom-in" type="submit">{{buttonLabel}}</button>
  </form>
</template>

<style>
  
</style>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as Ladda from 'ladda';

import LocalizedText from '@root/src/client/components/panels/input/LocalizedText.vue';
import DateInput from '@root/src/client/components/panels/input/DateInput.vue';
import SwitchCheck from '@fwk-client/components/panels/input/SwitchCheck.vue';
import Select from '@fwk-client/components/panels/input/Select.vue';

import { useAvailabilityAdmin } from '../../../composables/useAvailabilityAdmin';
import { useApartmentAdmin } from '../../../composables/useApartmentAdmin';

export default defineComponent({
  props: {
      availability: {
        type: Object as PropType<any>,
        required: false
      }
  },
  components: {
    DateInput,
    SwitchCheck,
    LocalizedText,
    Select
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { apartments, isListLoading: isApartmentListLoading, selectedApartment  } = useApartmentAdmin(props, context);
    const { availabilityForm, updateAvailabilityFormForUpdate, updateAvailability, createAvailability, getApartmentAvailabilities  } = useAvailabilityAdmin(props, context);

    const availableLanguageCodes = ['fr','en'];

    const { availability } = toRefs(props);

    const createFromForm:Ref<any> = ref({});
    const createFormAvailabilities:Ref<any> = ref([]);

    if(props.availability) {
      updateAvailabilityFormForUpdate(props.availability);
    }

    const buttonLabel = computed(() => {
      if(availability.value) { return app.$t('hospitality.availabilities.update.button') }
      else { return 'Create'; }
    })

    var laddaSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var button:HTMLButtonElement|null = document.querySelector( 'form[name=availability] button.ladda-button.availability' );
      laddaSubmit = Ladda.create(button!);
    })

    const allowCreateFrom = props.availability == undefined;

    const createFromAvailability = () => {
      if(createFromForm.value.availability) {
          updateAvailabilityFormForUpdate(createFromForm.value.availability);
      }
    }

    const createFromApartments = computed(() => {
      return apartments.value.filter((apartment:any) => {
        return apartment._id != selectedApartment.value.apartment._id
      })
    })

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaSubmit!.start();

      if(availability.value) {
        updateAvailability().then((result:any) => {
          if(result.updated) {
            context.emit('availability-updated', result.availability);
          }
          laddaSubmit!.stop();
        })
      }
      else {
        createAvailability().then((result:any) => {
          if(result.created) {
            context.emit('availability-created', result.availability);
          }
          laddaSubmit!.stop();
        })        
      }
    }

    watch(
      availability,
      (val:any, oldVal:any) => {
        // We update the form with new availability
        updateAvailabilityFormForUpdate(availability.value);
      },
      { deep: true }
    )

    watch(
      () => createFromForm.value.apartment,
      (val:any, oldVal:any) => {
        if(createFromForm.value.apartment) {
          // We update the list of availabilities
          getApartmentAvailabilities(createFromForm.value.apartment._id).then((response) => {
            createFormAvailabilities.value = response.availabilities;
            createFromForm.value.availability = undefined;
          });
        }
        else {
          createFormAvailabilities.value = [];
        }
      },
      { deep: true }
    )

    return {
      availability,
      availableLanguageCodes,
      availabilityForm,
      buttonLabel,
      onFormSubmit,
      allowCreateFrom,
      createFromAvailability,
      createFormAvailabilities,
      createFromApartments,
      createFromForm
    }
  }
})
</script>