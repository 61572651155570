import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { types as hospitalityTypes } from '@root/src/client/modules/hospitality/store';
import { useAgencyAdmin } from "./useAgencyAdmin";
import { useApartmentAdmin } from "./useApartmentAdmin";
import { formatDateForInput } from "@igotweb-node-api-utils/formatter";

interface AvailabilityAdminInput {
}

export function useAvailabilityAdmin(props:AvailabilityAdminInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();
  
  const { selectedAgency, callAgencyAdmin } = useAgencyAdmin(props, {emit})
  const { selectedApartment } = useApartmentAdmin(props, {emit})

  var isListLoading:Ref<boolean> = ref(false);

  const isAvailabilityUpdateAllowed:Ref<boolean> = ref(false);
  const isAvailabilityCreateAllowed:Ref<boolean> = ref(false);
  const isAvailabilityRemoveAllowed:Ref<boolean> = ref(false);
  const isAvailabilityPublishAllowed:Ref<boolean> = ref(false);

  const getApartmentAvailabilities = async (apartmentID:string) => {
    var response:any = {};
    try {
      response = await callAgencyAdmin('/apartment/'+apartmentID+'/availabilities/list');
    }
    catch(error:any) {
      console.log(error);
    }
    return response;
  }

  const updateListAvailabilities = async () => {

    isListLoading.value = true;
    try {
      if(selectedApartment.value != undefined) {
        var response = await getApartmentAvailabilities(selectedApartment.value.apartment._id);
        store.commit('hospitality/'+hospitalityTypes.mutations.SET_USER_AVAILABILITIES, response.availabilities ? response.availabilities : []);
        if(response.options) {
          isAvailabilityUpdateAllowed.value = response.options.isUpdateAllowed;
          isAvailabilityCreateAllowed.value = response.options.isCreateAllowed;
          isAvailabilityRemoveAllowed.value = response.options.isRemoveAllowed;
          isAvailabilityPublishAllowed.value = response.options.isPublishAllowed;
        }
      }
      else {
        store.commit('hospitality/'+hospitalityTypes.mutations.SET_USER_AVAILABILITIES, []);
      }
      
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
  }
  updateListAvailabilities();

  
  const availabilities:Ref<any[]> = computed(() => {
    return store.getters['hospitality/'+hospitalityTypes.getters.GET_USER_AVAILABILITIES];
  })


  // We check when there is a new availability selected
  watch(
    selectedApartment,
    (val:any, oldVal:any) => {
      // We update the categories when the selected agency is updated
      updateListAvailabilities();
    },
    { deep: false }
  )

  const availabilityForm:any = reactive({
    title: '',
    // ratings: [],
    price: {
      amount: '',
      currencyCode: 'EUR'
    },
    isValidationRequired: false,
    isValidationRequiredAfterValidationDate: null,
    expectedValidationDate: null,
    lastDateBeforeValidationPeriod: null,
    startDate:null,
    endDate:null
  });

  const updateAvailabilityFormForUpdate = (availability:any) => {
    availabilityForm.availabilityID = (availability && availability._id) ? availability._id : undefined,
    availabilityForm.title =  availability.title
    availabilityForm.price = availability.ratings ? availability.ratings[0].price : {
      amount: '',
      currencyCode: 'EUR'
    }
    availabilityForm.isValidationRequired =  availability.periodValidation != undefined
    availabilityForm.isValidationRequiredAfterValidationDate =  availability.periodValidation && availability.periodValidation.isValidationRequiredAfterValidationDate ? availability.periodValidation.isValidationRequiredAfterValidationDate : false
    availabilityForm.expectedValidationDate =  availability.periodValidation && availability.periodValidation.expectedValidationDate ? formatDateForInput(availability.periodValidation.expectedValidationDate) : null
    availabilityForm.lastDateBeforeValidationPeriod =  availability.periodValidation && availability.periodValidation.lastDateBeforeValidationPeriod ? formatDateForInput(availability.periodValidation.lastDateBeforeValidationPeriod) : null
    availabilityForm.startDate =  availability.startDate ? formatDateForInput(availability.startDate) : null
    availabilityForm.endDate = availability.endDate ? formatDateForInput(availability.endDate) : null;
  }

  const createAvailability = async (extraForm?:any) => {
    var result:any = {
      created: false
    }

    var input = {
      "apartmentID" : selectedApartment.value.apartment._id,
      ...availabilityForm,
      ...(extraForm ? extraForm : {})
    }

    try {
      var response = await callAgencyAdmin('/apartment/'+selectedApartment.value.apartment._id+'/availabilities/create', input);
      if(response.created) {  
        // We update the form
        updateAvailabilityFormForUpdate(response.availability);

        // We update the list
        updateListAvailabilities();

        result.created = true;
        result.availability = response.availability;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const updateAvailability = async (extraForm?:any) => {

    var result:any = {
      updated: false
    }

    var input:any = {
      ...availabilityForm,
      ...(extraForm ? extraForm : {})
    }

    try {
      var response = await callAgencyAdmin('/apartment/'+selectedApartment.value.apartment._id+'/availability/'+availabilityForm.availabilityID+'/update', input);
      if(response.updated) {  
        // We update the form
        updateAvailabilityFormForUpdate(response.availability);

        // We update the list
        updateListAvailabilities();

        result.updated = true;
        result.availability = response.availability;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const removeAvailability = async (availabilityID:string) => {
    isListLoading.value = true;
    try {
      var response = await callAgencyAdmin('/apartment/'+selectedApartment.value.apartment._id+'/availability/'+availabilityID+'/remove');
      if(response.removed) {
        updateListAvailabilities();
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return true;
  }

  const getPublishTargetAvailabilities = async () => {
    return callAgencyAdmin('/apartment/'+selectedApartment.value.apartment._id+'/deployment/availabilities').then((response:any) => {
      return response.availabilities
    });
  }

  const publishAvailabilities = async () => {
    var result:any = {
      published: false
    }

    try {
      var path = '/apartment/'+selectedApartment.value.apartment._id+'/deployment/publish-availabilities';
      var response = await callAgencyAdmin(path);
      if(response.availabilities) {  
        result.published = true;
        result.availabilities = response.availabilities;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const unavailablePeriodForm:any = reactive({
    availabilityID: undefined,
    reason: '',
    startDate:null,
    endDate:null
  });

  const addUnavailablePeriod = async () => {
    var result:any = {
      updated: false
    }

    var input = {
      ...unavailablePeriodForm
    }

    try {
      var response = await callAgencyAdmin('/apartment/'+selectedApartment.value.apartment._id+'/availability/'+unavailablePeriodForm.availabilityID+'/add-unavailable-period', input);
      if(response.updated) {  
        // We update the list
        updateListAvailabilities();

        result.updated = true;
        result.availability = response.availability;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const removeUnavailablePeriod = async (availabilityID:string, unavailablePeriodIndex:number) => {
    var result:any = {
      updated: false
    }

    var input:any = {
      unavailablePeriodIndex
    }

    try {
      var response = await callAgencyAdmin('/apartment/'+selectedApartment.value.apartment._id+'/availability/'+availabilityID+'/remove-unavailable-period', input);
      if(response.updated) {  

        // We update the list
        updateListAvailabilities();

        result.updated = true;
        result.availability = response.availability;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  return {
    isListLoading,
    availabilities,
    availabilityForm,
    updateAvailabilityFormForUpdate,
    updateAvailability,
    createAvailability,
    removeAvailability,
    getPublishTargetAvailabilities,
    publishAvailabilities,
    isAvailabilityUpdateAllowed,
    isAvailabilityCreateAllowed,
    isAvailabilityRemoveAllowed,
    isAvailabilityPublishAllowed,
    getApartmentAvailabilities,
    unavailablePeriodForm,
    addUnavailablePeriod,
    removeUnavailablePeriod
  }
  
}